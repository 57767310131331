import React, {useState} from "react";
import Connection from "../../Connection";
import "./styles.css";
import IosCheckmark from "react-ionicons/lib/IosCheckmark";
import 'react-html5-camera-photo/build/css/index.css';

const parse = require('html-react-parser');

function Formulario(props) {
    const [respostas, setRespostas] = useState({});
    const [state, setState] = useState({});
    let {configuracao} = props;

    let validate = (respostas, valid) => {
        props.onChange && props.onChange({
            valid: valid,
            content: respostas
        });
    };

    return <div className={"formulario esc_multipla"}>
        {configuracao.perguntas.map(pergunta => <div className={"pergunta"} key={"pergunta_" + pergunta.id_sessao}>
            <h4>{pergunta.designacao}</h4>
            <div className={"opcoes"}>
                {pergunta.opcoes.map((opcao, idx) => <div className={"opcao"} key={"opcao_" + idx} onClick={() => {
                    let _respostas = respostas;
                    _respostas[pergunta.id_sessao] = {opcao: opcao.valor, texto: pergunta.designacao + " - " + opcao.designacao};
                    setRespostas({..._respostas});
                    validate(_respostas, Object.keys(_respostas).length === configuracao.perguntas.length);
                }}>
                    <p>{opcao.designacao}</p>
                    <span className={"select-opcao" + (respostas[pergunta.id_sessao] && respostas[pergunta.id_sessao].opcao === opcao.valor ? " active" : "")}>
                                <IosCheckmark color={"white"} fontSize={"21px"}/>
                            </span>
                </div>)}
            </div>
        </div>)}
    </div>

}

class FeedbackEvento extends React.Component {
    constructor(props) {
        super(props);

        this.dados = {};

        this.state = {
            configuracao: props.configuracao,
            dados: props.dados,
            valido: false,
            comentario: ''
        };
    }

    submeter = () => {
        if (this.state.valido) {
            Connection.post("utilizador/submeter_feedback_evento", {
                sessoes: this.dados,
                comentario: this.state.comentario
            }, (data) => {
                this.props.onSubmit && this.props.onSubmit();
            })
        }
    };

    render() {
        let {dados, configuracao} = this.state;

        return <div className={"FormularioFeedback container"}>
            <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
                {configuracao.instrucoes && <p className={"instrucoes"}>{configuracao.instrucoes && parse(configuracao.instrucoes.split("\n").join("<br/>"))}</p>}
                <Formulario configuracao={configuracao} onChange={(data) => {
                    this.dados = data.content;
                    this.setState({
                        valido: data.valid
                    });
                }}/>
                <div className={"formulario texto_livre"}>
                    <h4>Comentário geral sobre o evento <small>(opcional)</small></h4>
                    <textarea className={"margin-top"} onChange={(e) => {
                        this.setState({comentario: e.target.value});
                    }}/>
                </div>
            </div>
            <div className={"text-center"} style={{marginTop: 30}}>
                <button className={"btn btn-primary " + (this.state.valido ? "" : "disabled")} onClick={this.submeter}>Enviar</button>
            </div>
        </div>;
    }
}

export default FeedbackEvento;