import React, {useEffect} from "react";
import QrReader from 'react-qr-reader'
import Connection from "../../Connection";
import General from "../../General";
import IosArrowBack from "react-ionicons/lib/IosArrowBack";
import "./styles.css";
import {useParams, withRouter} from "react-router-dom";
import moment from "moment";

function Checkin(props) {
    function iOSversion() {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
            // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
            var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
            return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        }
    }

    let ios_version = iOSversion();

    let state = {
        delay: 500,
        ios_version: ios_version ? ios_version[0] : 20
    };

    const [qrLegacy, setQrLegacy] = React.useState(false);
    const [codigo, setCodigo] = React.useState("");
    const [sessao, setSessao] = React.useState(null);
    const sending = React.useRef(false);
    const last_code = React.useRef("");
    const last_code_retry = React.useRef(0)

    let {id_sessao} = useParams()

    useEffect(() => {
        if (id_sessao)
            Connection.get("sessoes/detalhes/" + id_sessao, null, (data) => {
                setSessao(data);
            });
    }, [id_sessao]);

    if (id_sessao && !sessao)
        return null;

    async function handleScan(data) {
        if (data !== null) {
            if (last_code.current === data && !qrLegacy) {
                last_code_retry.current++;
                if (last_code_retry.current < 3) {
                    sending.current = false;
                    return;
                }
            }
            last_code.current = data;
            last_code_retry.current = 0;
            try {
                if (sending.current)
                    return;
                sending.current = true;
                let result = await Connection.post("checkin/marcar", {email_utilizador: data, sessao: sessao?.id});
                setCodigo("");
                if (qrLegacy)
                    setQrLegacy(false)
                if (result) {
                    console.log(result)
                }
            } catch (e) {
            }
            sending.current = false;
        } else if (qrLegacy) {
            General.error("Não foi encontrado nenhum código válido. Tente novamente ou insira o código manualmente.");
        }
    }

    return <div className={"DesbloquearDesafio"}>
        <div className={"DesbloquearDesafioHeader page_header_padding noselect " + (qrLegacy ? "" : "fixed")}>
            <div className={"container width-100"}>
                    <span className={"button"}>
                        <IosArrowBack fontSize={"28px"} onClick={() => qrLegacy ? setQrLegacy(false) : props.history.goBack()}/>
                    </span>
                <div>
                    {sessao ? <>
                        <h3>Checkin na Sessão '{sessao.titulo}'</h3>
                        <h4>{moment(sessao.inicio).format("H:mm") + " - " + sessao.local.designacao}</h4>
                    </> : <h3>Checkin no Evento</h3>}
                    {!qrLegacy && <button className="btn btn-primary btn-stroke btn-sm" style={{marginTop: 10}} onClick={() => setQrLegacy(true)}>Validar manualmente</button>}
                </div>
            </div>
        </div>
        {state.ios_version >= 10 && <div style={{display: qrLegacy ? "none" : "block"}}>
            <QrReader
                className={"QrCode"}
                legacyMode={qrLegacy}
                onError={(error) => setQrLegacy(true)}
                facingMode="environment"
                delay={state.delay}
                showViewFinder={false}
                onScan={handleScan}
                style={{width: '100%'}}/>
        </div>}
        {state.ios_version >= 10 && qrLegacy && (<div className={"legacy"}>
            <h2 style={{marginTop: 0}}>Validar participante por e-mail</h2>
            <div className="text-center container">
                <input type={"email"} className={"codigo_manual"} onChange={(e) => {
                    setCodigo(e.target.value);
                }} value={codigo}/>
                <button className="btn btn-primary" type="submit" style={{marginTop: 10}} onClick={() => {
                    handleScan(codigo)
                }}>Validar
                </button>
            </div>
        </div>)}
    </div>;
}

export default withRouter(Checkin);