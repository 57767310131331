import React, {useState} from "react";
import './styles.css';
import Connection from "../../Connection";
import noavatar from "../../../img/noavatar.svg";
import {Link, withRouter} from "react-router-dom";
import ItemsCarousel from 'react-items-carousel';
import List from "../../components/list";
import moment from "moment";
import TextTransition, {presets as text_transition_presets} from "react-text-transition";
import IosPin from "react-ionicons/lib/IosPin";
import IosPeople from "react-ionicons/lib/IosPeople";
import IosPerson from "react-ionicons/lib/IosPerson";
import placeholderSessao from "../../../img/placeholder_sessao.jpg";
import placeholderNoticia from "../../../img/placeholder_noticia.svg";
import Modal from "../../components/modal";
import PersonalizarPrograma from "../personalizar_programa";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import Desafio from "../desafio";
import Loading from "../../components/loading";
import FeedbackEvento from "../feedback_evento";
//import qrCodeReaderIcon from "../../../img/qr_code_scanner.svg";

var QRCode = require('qrcode.react');
const parse = require('html-react-parser');

class HomeHeader extends React.Component {
    constructor(props) {
        super(props);

        this.opterNotificacoes();

        this.state = {
            estadoNotificacoes: null
        }
    }

    opterNotificacoes = async () => {
        let estado = await Connection.get("utilizador/notificacoes/estado");
        let textoNotificacoes = "";
        let {novas, contagem} = estado;
        textoNotificacoes = <span>Não tem notificações novas</span>;
        if (novas > 0)
            textoNotificacoes = <span style={{color: "#FB0200"}}>Tem {novas} {novas === 1 ? " notificação nova" : " notificações novas"}</span>;
        this.setState({estadoNotificacoes: textoNotificacoes});
    };

    render() {
        let utilizador = window.App.state.utilizador;
        return <div className="HomeHeader page_header_padding flex container">
            <div className="flex-1">
                <h2>Olá, {utilizador.nome}</h2>
                <Link to={"notificacoes"}><p><TextTransition
                    inlne={true}
                    text={this.state.estadoNotificacoes || "A carregar notificações..."}
                    springConfig={this.state.estadoNotificacoes ? text_transition_presets.stiff : {duration: 0}}
                /></p></Link>
            </div>
            <Link to={"codigo"}><QRCode className={"qrcode"} renderAs={"svg"} value={utilizador.email}/></Link>
            <Link to={"perfil"}><img src={utilizador.imagem ? "" + Connection.getApiBase() + "imagem/utilizador/" + utilizador.imagem : noavatar} className="avatar"/></Link>
        </div>;
    }
}

class HomeCarousel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0
        }
    }

    Card = (props) => {
        return <div className={"CardWrapper noselect"}>
            <div className={"Card"} style={{backgroundImage: "url(" + props.image + ")"}} onClick={props.current ? (() => props.action && props.action(props)) : null}>
                {props.loading ? <div className={"placeholder"}><Loading/></div> : <div className={"content"}>
                    <div style={{display: "flex", alignItems: "flex-end"}}>
                        <div style={{flex: 1}}>
                            <h4>{props.title}</h4>
                            <h5>{props.subtitle}</h5>
                        </div>
                        {props.showMapIcon && <IosPin fontSize="28px" color={"white"} onClick={(e) => {
                            e.stopPropagation();
                            props.showMapCallback()
                        }}/>}
                    </div>
                </div>}
            </div>
        </div>;
    };

    componentDidMount() {
        setTimeout(() => {
            const height = this.wrapperRef.clientHeight;
            this.props.saveHeight && this.props.saveHeight(height);
        }, 100);
    }

    render() {
        return <div className={"HomeCarousel"} ref={(ref) => {
            this.wrapperRef = ref
        }}>
            <div className={"container"}>
                <ItemsCarousel
                    requestToChangeActive={(idx) => {
                        if (idx > -1 && idx < this.props.cards.length) {
                            this.setState({activeIndex: idx});
                            this.props.onChange && this.props.onChange(idx);
                        }
                    }}
                    activeItemIndex={this.state.activeIndex}
                    numberOfCards={1}
                    gutter={15}
                    enablePlaceholder={true}
                    placeholderItem={<this.Card current={false} loading={true}/>}
                    numberOfPlaceholderItems={2}
                    classes={{itemsWrapper: "itemsWrapper", itemWrapper: "itemWrapper", wrapper: "wrapper"}}
                >
                    {this.props.cards.map((c, idx) =>
                        <div onClick={(e) => {
                            if (this.state.activeIndex !== idx) {
                                this.setState({activeIndex: idx});
                                this.props.onChange && this.props.onChange(idx);
                            }
                        }}>
                            <this.Card {...c} current={this.state.activeIndex === idx}/>
                        </div>)}
                </ItemsCarousel>
            </div>
        </div>;
    }
}

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.opterInformacoes();

        this.state = {
            carouselHeight: 0,
            proximas_sessoes: [],
            desafios: null,
            slots_pendentes: [],
            noticias: []
        }
    }

    opterInformacoes = async () => {
        let inicio = await Connection.get("inicio");
        inicio.desafios.sort((a, b) => {
            return !!a.data_resposta - !!b.data_resposta;
        });
        this.setState({...inicio, mostrar_sessoes: inicio.proximas_sessoes && inicio.proximas_sessoes.length > 0 || inicio.noticias.length === 0});
    };

    opterSlotsPendentes = async () => {
        this.setState({
            slots_pendentes: await Connection.get("sessoes/slots_pendentes")
        });
    };

    obterDesafios = async () => {
        let desafios = await Connection.get("jogo/desafio/utilizador");
        desafios.sort((a, b) => {
            return !!a.data_resposta - !!b.data_resposta;
        });
        this.setState({desafios: desafios});
    };

    personalizarPrograma = () => {
        window.App.addOverlay(<Modal key={"modal_personalizar_programa"} title={"Personalizar Programa"} content={(hide) => {
            return <PersonalizarPrograma ocultarSelecionados onSuccess={() => {
                hide();
                this.opterSlotsPendentes();
            }}/>
        }}/>);
    };

    conteudoCaroussel = () => {
        if (this.state.mostrar_sessoes)
            return this.state.proximas_sessoes.map((sessao) => {
                return {
                    title: sessao.titulo,
                    subtitle: moment(sessao.inicio).format("H:mm") + (sessao.local ? " | " + sessao.local.designacao : ''),
                    image: sessao.imagem ? Connection.getApiBase() + sessao.imagem : (sessao.local && sessao.local.imagem ? Connection.getApiBase() + sessao.local.imagem : placeholderSessao),
                    showMapIcon: !!sessao.local,
                    showMapCallback: (c) => {
                        window.App.toggleMap({
                            title: sessao.local.grupo,
                            subtitle: (sessao.local.descricao ? sessao.local.descricao + ", " : '') + sessao.local.designacao,
                            marker: {
                                lat: sessao.local.coordenadas.latitude,
                                lng: sessao.local.coordenadas.longitude
                            }
                        });
                    },
                    action: () => {
                        if (window.App.hasMap())
                            window.App.hideMap();
                        else
                            this.props.history.push("/sessao/" + sessao.id);
                    }
                };
            });
        else {
            return this.state.noticias.map((noticia) => {
                return {
                    title: noticia.titulo,
                    subtitle: moment(noticia.data).format("D [de] MMMM [às] HH:mm"),
                    image: placeholderNoticia,
                    action: () => window.App.addOverlay(<Modal key={"modal_personalizar_programa"} title={noticia.titulo} content={(hide) => {
                        const [_noticia, setNoticia] = useState(null);
                        if (!_noticia) {
                            Connection.get("noticia/" + noticia.id, null, (data) => setNoticia(data));
                            return null;
                        }
                        return <div className={"container Noticia animated fadeInUp"}>
                            {_noticia.conteudo && <p>{parse(_noticia.conteudo)}</p>}
                            <h3 className="section-title separator">Data de publicação</h3>
                            <p>{moment(_noticia.data).format("D [de] MMMM [às] HH:mm")}</p>
                        </div>
                    }}/>)
                };
            })
        }
    };

    render() {
        return <div className="Home">
            <style>
                {'\
                .withMap .Home { \
                    margin-top: ' + (window.innerHeight - 240 - this.state.carouselHeight) + 'px;\
                }\
                '}
            </style>
            <div className={"hideWithMap"}>
                <HomeHeader/>
                <h3 className={"container section-title"}>{this.state.mostrar_sessoes ? "Próximas Sessões" : "Notícias"}</h3>
            </div>
            <HomeCarousel
                saveHeight={(height) => {
                    this.setState({carouselHeight: height});
                }}
                cards={this.conteudoCaroussel()}
                onChange={(index) => {
                    if (window.App.hasMap()) {
                        let sessao = this.state.proximas_sessoes[index];
                        if (sessao.local)
                            window.App.showMap({
                                title: sessao.local.grupo,
                                subtitle: (sessao.local.descricao ? sessao.local.descricao + ", " : '') + sessao.local.designacao,
                                marker: {
                                    lat: sessao.local.coordenadas.latitude,
                                    lng: sessao.local.coordenadas.longitude
                                }
                            });
                    }
                }}/>
            <div className={"container hideWithMap"}>
                {this.state.slots_pendentes.length > 0 &&
                    <div className={"cta noselect"} onClick={this.personalizarPrograma}>
                        <p>Personalize a sua agenda</p>
                        <IosArrowForward color={"#ffffff"}/>
                    </div>}
                <h3 className={"section-title"}>Desafios
                    {/*<button className="btn btn-primary btn-stroke btn-xs" style={{float: "right", margin: "-5px 0"}} onClick={() => this.props.history.push("/desafio/desbloquear")}><img className={"svg"} src={qrCodeReaderIcon}/> Desbloquear Desafio</button>*/}
                </h3>
                {!this.state.feedback ? null :
                    <div style={{marginBottom: 15}}><List appearance={"desafio"} items={[{
                        title: this.state.feedback.titulo,
                        text: "Clique para responder",
                        icon: <IosPerson color={"#881514"}/>,
                        action: () => {
                            window.App.addOverlay(<Modal title={this.state.feedback.titulo} content={(hide) => {
                                return <FeedbackEvento configuracao={this.state.feedback} onSubmit={() => {
                                    this.setState({feedback: null});
                                    hide();
                                }}/>
                            }}/>);
                        }
                    }
                    ]}/></div>}
                {!this.state.desafios ? <Loading/> : this.state.desafios.length ?
                    <List appearance={"desafio"} items={this.state.desafios.map((desafio) => {
                        return {
                            title: desafio.titulo,
                            text: desafio.data_resposta ? "Desafio respondido" : "Clique para responder",
                            icon: {equipa: <IosPeople color={"#881514"}/>, individual: <IosPerson color={"#881514"}/>}[desafio.tipo],
                            action: !desafio.data_resposta && (() => {
                                window.App.addOverlay(<Modal title={desafio.titulo} content={(hide) => {
                                    return <Desafio desafio={desafio} onSubmit={() => {
                                        this.obterDesafios();
                                        hide();
                                    }}/>
                                }}/>);
                            }),
                            points: desafio.pontos_calculados ? <span>{desafio.pontos_calculados}
                                <small>ponto{desafio.pontos_calculados === 1 ? "" : "s"}</small></span> : null
                        };
                    })}/>
                    : (!this.state.feedback && <p className={"section-placeholder"}>Não tem desafios por responder</p>)
                }
                {this.state.documentos && this.state.documentos.length ? <h3 className={"section-title"}>Documentos</h3> : null}
                {this.state.documentos && <List appearance={"download"} items={this.state.documentos}/>}
            </div>
        </div>


    }
}

export default withRouter(Home);