import React from "react";
import Connection from "../../Connection";
import Loading from "../../components/loading";
import './styles.css';
import headerBg from "./bg.jpg";
import headerLogo from "./logoiiijipe.svg";
import Modal from "../../components/modal";
import Feedback from "../feedback";

const parse = require('html-react-parser');

class Info extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            informacoes: null,
        };

        Connection.get("sobre", null, (data) => {
            console.log(data);
            this.setState({informacoes: data});
        })
    }

    render() {
        return <div className={"Info"}>
            <div className={"header_image"} style={{backgroundImage: "url(" + headerLogo + "), url(" + headerBg + ")"}}/>
            {this.state.informacoes ?
                <div className={"container animated fadeInUp"}>
                    <button
                        className="btn btn-block btn-primary btn-stroke"
                        style={{marginTop: 20, fontSize: 14}}
                        onClick={() => window.App.addOverlay(<Modal title={"FEEDBACK"} content={(hide) => {
                            return <Feedback onSubmit={hide}/>
                        }}/>)}
                    >Dê-nos o seu feedback sobre a aplicação (versão {Connection.APP_VERSION})
                    </button>
                    <div className={"dias"}>
                        <div className={"dia"}>
                            <p>{this.state.informacoes.edicao.data_texto}<br/>{this.state.informacoes.edicao.local_texto}</p>
                        </div>
                    </div>
                    <div className={"description"}>
                        {parse(this.state.informacoes.edicao.descricao)}
                    </div>
                    <h3 className={"section-title separator"}>Promotores</h3>
                    <div className={"promotores"}>
                        {this.state.informacoes.promotores.filter(p => p.destacado).map((p) => <div className={"promotor destacado"}>
                            <span className={"img"} style={{backgroundImage: "url(" + Connection.getApiBase() + "imagem/parceiro/" + p.imagem + ")"}}/>
                        </div>)}
                        <div style={{marginTop: 20}}/>
                        {this.state.informacoes.promotores.filter(p => !p.destacado).map((p) => <div className={"promotor"}>
                            <span className={"img"} style={{backgroundImage: "url(" + Connection.getApiBase() + "imagem/parceiro/" + p.imagem + ")"}}/>
                        </div>)}
                    </div>
                    <h3 className={"section-title separator"}>Parceiros</h3>
                    <div className={"parceiros"}>
                        {this.state.informacoes.parceiros.map((p) => <div className={"parceiro"}>
                            <span className={"img"} style={{backgroundImage: "url(" + Connection.getApiBase() + "imagem/parceiro/" + p.imagem + ")"}}/>
                        </div>)}
                    </div>
                </div>
                : <Loading/>}
        </div>;
    }
}

export default Info;