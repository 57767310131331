import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/App';

import './css/general.css';
import './css/text.css';
import './css/buttons.css';
import './css/forms.css';
import './css/animations.css';

window.APP_VERSION = 1;

if (!String.linkify) {
    String.prototype.linkify = function () {

        // http://, https://, ftp://
        var urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim;

        // www. sans http:// or https://
        var pseudoUrlPattern = /(^|[^/])(www\.[\S]+(\b|$))/gim;

        // Email addresses
        var emailAddressPattern = /[\w.]+@[a-zA-Z_-]+?(?:\.[a-zA-Z]{2,6})+/gim;

        return this
            .replace(urlPattern, '<a target="_blank" href="$&">$&</a>')
            .replace(pseudoUrlPattern, '$1<a target="_blank" href="http://$2">$2</a>')
            .replace(emailAddressPattern, '<a href="mailto:$&">$&</a>');
    };
}

if (!Object.values) {
    Object.values = function (obj) {
        return Object.keys(obj).map(function (key) {
            return obj[key];
        });
    }
}

const homepage = require("../package.json").homepage;

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register(homepage + "firebase-messaging-sw.js")
        .then(function (registration) {
            console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function (err) {
            console.log("Service worker registration failed, error:", err);
        });
}

ReactDOM.render(<App/>, document.getElementById('root'));
//registerServiceWorker();
