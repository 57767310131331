import React from "react";
import Form from "react-jsonschema-form";
import General from "../../General";
import Connection from "../../Connection";
import "./styles.css";
import logo from "../../../img/logo.svg"

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.loginSchema = {
            type: "object",
            required: ["email", "password"],
            properties: {
                email: {type: "string", title: "E-mail"},
                password: {type: "string", title: "Password"},
            }
        };

        this.uiLoginSchema = {
            password: {
                "ui:widget": "password"
            },
            email: {
                "ui:widget": "email"
            }
        };
    }

    login = async (e) => {
        let {email, password} = e.formData;
        let data = await Connection.post("login", {email: email, password: password});
        General.success("Bem-vindo!");
        Connection.enviarInfoSessao();
        window.App.setState({
            utilizador: data.utilizador
        });
    };

    render() {
        return <div className="Login container flex">
            <img src={logo} className="logo flex"/>
            <div>
                <h1 className="bemVindo">Bem-vindo</h1>
                <Form className={"flex"} noHtml5Validate={true} liveValidate={true} transformErrors={General.formTransformErrors} FieldTemplate={General.formCustomFieldTemplate} showErrorList={false} schema={this.loginSchema} uiSchema={this.uiLoginSchema} onSubmit={this.login}>
                    <div className="form-group flex field text-center">
                        <button className="btn btn-primary" type="submit" style={{marginTop: 40}}>Login</button>
                        <p className={"text-center"} style={{marginTop: 30}}><a href={"https://pensamentoemocional.pt/#recover"} target={"_blank"}>Esqueceu a sua password?</a></p>
                    </div>
                </Form>
            </div>
            <p className={"text-center"} style={{marginTop: 15}}>Ainda não tem conta? <a href={"https://pensamentoemocional.pt/#register"}>Criar agora</a></p>
        </div>
    }
}

export default Login;