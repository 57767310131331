import React, {useEffect, useState} from "react";
import './styles.css';
import Connection from "../../Connection";
import {withRouter} from "react-router-dom";
import List from "../../components/list";
import moment from "moment";

function HomeCheckin(props) {
    const [sessoes, setSessoes] = useState([]);
    const [estatisticas, setEstatisticas] = useState({});
    useEffect(() => {
        Connection.get("sessoes/opcionais", {}, (data) => {
            setSessoes(data.sessoes);
        });
        Connection.get("checkin/estatisticas", {}, (data) => {
            setEstatisticas(data);
        });
    }, []);

    return <div className="Home">


        <div className={"container hideWithMap"}>
            <h3 className={"section-title"}>Estatísticas</h3>
            <List appearance={"desafio"} items={[
                {
                    title: "Registos",
                    text: estatisticas.registos
                }, {
                    title: "Checkins Hoje",
                    text: estatisticas.presentes_edicao_hoje
                }
            ]}/>
            <h3 className={"section-title"}>Checkin no evento</h3>
            <div className={"text-center"}>
                <button className="btn btn-primary" onClick={() => props.history.push("/checkin")}>Checkin no Evento</button>
            </div>
            <h3 className={"section-title"}>Checkin nas sessões</h3>
            <List appearance={"desafio"} items={sessoes.map((sessao) => {
                return {
                    title: sessao.titulo,
                    text: moment(sessao.inicio).format("H:mm") + " - " + sessao.local.designacao,
                    action: () => {
                        props.history.push("/checkin/" + sessao.id);
                    }
                };
            })}/>
            <div className={"text-center"} style={{marginTop: 20}}>
                <button onClick={window.App.logout} className="btn btn-sm" type="submit" style={{marginLeft: 5}}>Logout</button>
            </div>
        </div>
    </div>
}

export default withRouter(HomeCheckin);