import React from "react";
import Connection from "../../Connection";
import moment from 'moment';
import 'moment/locale/pt';
import Loading from "../../components/loading";
import './index.css';
import IosArrowBack from "react-ionicons/lib/IosArrowBack";
import IosPin from "react-ionicons/lib/IosPin";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import placeholderSessao from "../../../img/placeholder_sessao.jpg";
import Modal from "../../components/modal";
import Orador from "../orador";

moment.locale("pt");

const parse = require('html-react-parser');

class Sessao extends React.Component {
    constructor(props) {
        super(props);

        const params = this.props.match ? this.props.match.params : {id: this.props.id};

        let _this = this;
        Connection.get("sessoes/detalhes/" + params.id, null, (data) => {
            _this.setState({sessao: data});
        });

        this.state = {
            sessao: null,
        };
    }

    render() {
        if (!this.state.sessao)
            return <Loading/>;

        let sessao = this.state.sessao;
        return <div className={"Sessao"}>
            <div className={"header noselect"} style={{backgroundImage: "url(" + (sessao.imagem ? Connection.getApiBase() + sessao.imagem : (sessao.local && sessao.local.imagem ? Connection.getApiBase() + sessao.local.imagem : placeholderSessao)) + ")"}} onClick={() => {
                if (window.App.hasMap()) {
                    window.App.hideMap();
                }
            }}>
                <div className={"header_content page_header_padding"}>
                    <div className={"container width-100"}>
                        <span className={"button"}>
                            <IosArrowBack fontSize={"28px"} color={"white"} onClick={() => {
                                this.props.history && this.props.history.goBack();
                                this.props.onBack && this.props.onBack()
                            }}/>
                        </span>
                    </div>
                    <div className={"container header_text"}>
                        <h3>{this.state.sessao.titulo}</h3>
                        <p>{moment(this.state.sessao.inicio).format("D MMMM | HH:mm")}</p>
                    </div>
                </div>
            </div>
            <div className={"content animated fadeInUp"}>
                {this.state.sessao.local && <div className={"sessionInformation"}>
                    <div className={"sectionTitle"}>
                        <h3 className={"container"}>Local</h3>
                    </div>
                    <div className={"container local"} onClick={() => {
                        window.App.showMap({
                            title: this.state.sessao.local.grupo,
                            subtitle: (this.state.sessao.local.descricao ? this.state.sessao.local.descricao + ", " : '') + this.state.sessao.local.designacao,
                            marker: {
                                lat: this.state.sessao.local.coordenadas.latitude,
                                lng: this.state.sessao.local.coordenadas.longitude
                            }
                        });
                    }}>
                        <div className={"texto"}>
                            <h4>{this.state.sessao.local.grupo}</h4>
                            <p>{(this.state.sessao.local.descricao ? (this.state.sessao.local.descricao + ", ") : "") + this.state.sessao.local.designacao}</p>
                        </div>
                        <div className={"button"}>
                            <IosPin fontSize="30px" color={"#3e3e3e"}/>
                        </div>
                    </div>
                </div>}
                {this.state.sessao.descricao && <div className={"sessionInformation"}>
                    <div className={"sectionTitle"}>
                        <h3 className={"container"}>Sobre a sessão</h3>
                    </div>
                    <div className={"container"}>
                        <p>{parse(this.state.sessao.descricao.split("\n").join("<br/>"))}</p>
                    </div>
                </div>}
                {this.state.sessao.oradores.length > 0 && <div className={"sessionInformation"}>
                    <div className={"sectionTitle"}>
                        <h3 className={"container"}>Oradores</h3>
                    </div>
                    <div className={"container oradores"}>
                        {this.state.sessao.oradores.map(orador => <div onClick={() => {
                            window.App.addOverlay(<Modal key={"modal_personalizar_programa"} title={"ORADOR"} content={(hide) => {
                                return <Orador id={orador.id} history={this.props.history} hideOverlay={hide}/>
                            }}/>);
                        }} className={"orador"}>
                            <span className={"image"} style={{backgroundImage: "url(" + Connection.getApiBase() + orador.imagem + ")"}}/>
                            <h4>{orador.nome}</h4>
                            <IosArrowForward color={"#e2e2e2"} fontSize={"30px"}/>
                        </div>)}
                    </div>
                </div>}
            </div>
        </div>;
    }
}

export default Sessao;