import React from "react";
import Connection from "../../Connection";
import 'moment/locale/pt';
import Loading from "../../components/loading";
import './index.css';
import SlotSessoes from "../../components/slot_sessoes";

class Orador extends React.Component {
    constructor(props) {
        super(props);

        const params = this.props.match ? this.props.match.params : {id: this.props.id};

        this.state = {
            orador: null,
        };

        Connection.get("orador/detalhes/" + params.id, null, (data) => {
            this.setState({orador: data});
        });
    }

    render() {
        if (!this.state.orador)
            return <Loading/>;

        let orador = this.state.orador;
        return <div className={"Orador container"}>
            <div className={"HeaderOrador"}>
                <span className={"img"} style={{backgroundImage: "url(" + Connection.getApiBase() + "imagem/orador/" + orador.imagem + ")"}}/>
                <h2>{orador.nome}</h2>
            </div>
            <div className={"content"}>
                <h3 className="section-title separator">Biografia</h3>
                <p>{orador.biografia}</p>
                <h3 className="section-title separator">Sessões</h3>
                {orador.sessoes.map(dia => {
                    return dia.slots.map(slot => <SlotSessoes temas={slot.temas} slot={slot.slot} mostrarDetalhesSessao={(id) => {
                        if (this.props.history) {
                            this.props.hideOverlay && this.props.hideOverlay();
                            this.props.history.push("/sessao/" + id);
                        }
                    }} mostrarData/>)
                })}
            </div>
        </div>;
    }
}

export default Orador;